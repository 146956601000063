// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "p_qF d_bC";
export var storyRowWrapper = "p_hw d_hw d_bJ";
export var storyLeftWrapper = "p_qG d_by d_bN";
export var storyWrapperFull = "p_qH d_cC";
export var storyWrapperFullLeft = "p_nM d_cC d_by d_bN d_bC";
export var contentWrapper = "p_n9 d_hx";
export var storyLeftWrapperCenter = "p_qJ d_hy d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "p_qK d_hD";
export var storyHeader = "p_qL d_hC d_v d_cr";
export var storyHeaderCenter = "p_hB d_hB d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "p_hz d_hz d_bx d_dv";
export var storyBtnWrapper = "p_qM d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "p_hH d_hH d_d0 d_v d_by d_bC";
export var imageWrapper = "p_qN d_ff d_Y";
export var imageWrapperFull = "p_qP d_v d_G d_bd d_Y";