// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "t_fP d_fP d_by d_bG d_bH";
export var navbarDividedRight = "t_fQ d_fQ d_by d_bH";
export var menuDesign6 = "t_st d_fL d_by d_dv d_bN d_bH";
export var menuDesign7 = "t_sv d_fL d_by d_dv d_bN d_bH";
export var menuRight = "t_sw d_fL d_by d_dv d_bN d_bH";
export var menuLeft = "t_sx d_fL d_by d_dv d_bN d_bH";
export var menuCenter = "t_sy d_fM d_fL d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "t_qW d_fM d_fL d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "t_q5 d_fN d_fL d_by d_dv d_bN d_bH";
export var isBurger = "t_sz";
export var navbarItem = "t_qX d_bw";
export var navbarLogoItemWrapper = "t_fX d_fX d_bB d_bN";
export var burgerToggleVisibleOpen = "t_sB d_gc d_bx d_Y d_br";
export var burgerToggleVisible = "t_sC d_gc d_bx d_Y d_br";
export var burgerToggle = "t_sD d_gc d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "t_sF d_gc d_bx d_Y d_br";
export var burgerInput = "t_sG d_f1 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "t_sH d_f2 d_v d_G";
export var burgerLine = "t_sJ d_f0";
export var burgerMenuDesign6 = "t_sK d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "t_sL d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "t_sM d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "t_sN d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "t_sP d_f7 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "t_sQ d_f8 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "t_sR d_f6 d_f5 d_f3 d_f4 d_Z d_cb d_dl d_br d_dv";
export var secondary = "t_sS d_bB d_bN";
export var staticBurger = "t_sT";
export var menu = "t_sV";
export var navbarDividedLogo = "t_sW";
export var nav = "t_sX";