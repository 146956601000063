// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "m_qj d_gt d_cr";
export var heroHeaderCenter = "m_gv d_gv d_cr d_dv";
export var heroHeaderRight = "m_gw d_gw d_cr d_dw";
export var heroParagraphLeft = "m_qk d_gq d_cv";
export var heroParagraphCenter = "m_gr d_gr d_cv d_dv";
export var heroParagraphRight = "m_gs d_gs d_cv d_dw";
export var heroBtnWrapperLeft = "m_ql d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "m_qm d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "m_qn d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "m_qp d_gp d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "m_qq d_gn d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "m_qr s_qr";
export var heroExceptionNormal = "m_qs s_qs";
export var heroExceptionLarge = "m_qt s_qt";
export var Title1Small = "m_qv s_qv s_rl s_rm";
export var Title1Normal = "m_qw s_qw s_rl s_rn";
export var Title1Large = "m_qx s_qx s_rl s_rp";
export var BodySmall = "m_qy s_qy s_rl s_rF";
export var BodyNormal = "m_qz s_qz s_rl s_rG";
export var BodyLarge = "m_qB s_qB s_rl s_rH";