// extracted by mini-css-extract-plugin
export var alignLeft = "n_pL d_fn d_bF d_dt";
export var alignCenter = "n_bN d_fp d_bC d_dv";
export var alignRight = "n_pP d_fq d_bG d_dw";
export var menuContainer = "n_qC d_dV";
export var menuContainerFull = "n_qD d_dS";
export var menuMainHeader = "n_jB d_jB d_v d_c2";
export var menuComponentWrapper = "n_jF d_jF d_ct";
export var menuComponentWrapperDesign2 = "n_jG d_jG d_cx";
export var menuComponentText = "n_jC d_jC d_cm";
export var menuComponentTextDesign2 = "n_jD d_jD d_cr";
export var menuImageWrapperDesign2 = "n_jH d_jH d_v d_Y";